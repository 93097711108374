export const routes = [
	{
		path: '/',
		label: 'Home'
	},
	{
		path: '/#about',
		label: 'About'
	},
	{
		path: '/#contact',
		label: 'Contact'
	}
	/*
	{
		path: '/portfolio',
		label: 'Portfolio'
	} */
];
